/*Var*/

@main-color: #5F4CA8;
@light-color: #b39ef2;
@text-color: #ffffff;
@accent1-color: #f0c538;
@accent2-color: #ff8db1;
@accent3-color: #00B8A2;
@shadow-color: #000000;

@media (max-width: 1600px) {

  html {
    font-size: 14px;
  }

}

@media (max-width: 1350px) {

  html {
    font-size: 13.5px;
  }
  .banner {
    background-position: left 29vw center;
  }

}

@media (max-width: 1250px) {

  html {
    font-size: 13px;
  }

  .wrapper {
    width: 90%;
  }

  /*Banner*/

  .main.index-page {
    background-size: 80%;
  }
  .banner {
    min-height: 60vh;
  }

  /*Slider*/
  .slider {
    &__image {
      width: 55%;
      margin-right: 10%;
    }
    .swiper {
      &-slide {
        justify-content: flex-start;
      }
      &-pagination {
        right: 0;
      }
    }
  }

  /*How*/
  .header {
    &_how {
      .header__logo {
        display: none;
      }
    }
  }
  .how {
    &__back {
      left: 0;
    }
  }

}

@media (max-width: 1100px) {

  /*Banner*/

  .banner {
    &__arrow {
      bottom: -7rem;
    }
  }
  @keyframes animate-arrow {
    0%,
    40%,
    100% {
      bottom: -7rem;
      transform: translateX(-50%);
    }
    20% {
      bottom: -10rem;
      transform: translateX(-50%) rotate(-15deg);
    }
  }

  /*Slider*/

  .slider {
    &__wrapper {
      justify-content: center;
    }
    &__title {
      width: 90%;
    }
    &__image {
      margin-right: 0;
      width: 60%;
    }
    .swiper {
      &-slide {
        justify-content: space-between;
        align-items: center;
      }
      &-pagination {
        flex-direction: initial;
        position: static;
        justify-content: center;
        span {
          margin-left: .5rem!important;
          margin-right: .5rem!important;
          width: .5rem!important;
          height: .5rem!important;
          position: static;
          &.swiper-pagination-bullet-active {
            width: .7rem!important;
            height: .7rem!important;
          }
        }
      }
    }
    &__buttons {
      margin-top: 7rem;
      &:not(.mobile) {
        display: none;
      }
      &.mobile {
        display: flex;
      }
    }
    &__more {
      width: initial;
      margin-right: 3rem;
    }
  }

  /*Howtobuy*/

  .how {
    &__price {
      width: 100%;
      text-align: center;
      >span {
        &:nth-of-type(1) {
          font-size: 1.5rem;
        }
      }
    }
    .howtobuy_click_price {
      display: block;
      width: 100%;
      text-align: center;
      &:not(.mobile) {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
    &__toons {
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      >span {
        &:nth-of-type(1) {
          font-size: 4rem;
        }
      }
    }
    &__pay {
      &-title {
        width: 100%;
        text-align: center;
      }
    }
    &__paragraph {
      width: 80%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    &__definition {
      width: 100%;
      span {
        width: 40%;
      }
    }
  }

  /*Vote*/

  .vote__form_balance {
    p {
      margin-bottom: 48px;
    }

    .balance {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: 1.2px;
    }

    img {
      width: 25px;
      margin-right: 4px;
      margin-top: 2px;
    }
  }

  .vote__form_balance h2 {
    margin-bottom: 16px;
  }

  .vote__form {
    width: 100%;

    h2 {
      text-align: center;
      margin-bottom: 16px;
    }

    label {
      word-break: break-all;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.6px;
      padding-left: 40px;
      margin-bottom: 30px;
    }

    input[type="text"] {
      height: 48px;
      padding: 0 16px;
      border-radius: 16px;
      font-size: 12px;

      &::placeholder {
        font-size: 12px;
      }
    }

    button {
      font-size: 16px;
      height: 56px;
      margin-top: 18px;
    }
  }

  .vote__form_message_success {
    font-size: 12px;
    max-width: 230px;
    margin: 16px auto 0;
  }

  .how__wrapper {
    h1 {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .vote-page {
    .how {
      padding-bottom: 48px;
    }
  }

  .vote__auth {
    h3 {
      font-size: 24px;
      line-height: 1.67;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }

    .button {
      height: 56px;
      margin-top: 48px;
    }
  }

  .vote__download_metamask_wrapper {
    h3 {
      font-size: 24px;
      line-height: 1.67;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }

    .button {
      height: 56px;
      margin-top: 48px;
    }
  }

}

@media (max-width: 900px) {

  html {
    font-size: 13px;
  }
  .title {
    font-size: 2.4rem;
  }
  .subtitle {
    font-size: 3rem;
  }

  /*Banner*/

  .banner {
    min-height: 70vh;
    background-position: left 20vw center;
    &__about {
      width: 70%;
    }
  }

  /*Animate*/

  .banner {
    &__subtitle {
      font-size: 1.4rem;
    }
    &__animate {
      &-title {
        font-size: 1.4rem;
        em {
          font-size: 1rem;
        }
      }
      &-video {
        border-radius: 3rem;
        &:not(.mobile) {
          display: none;
          position: absolute;
          width: 0;
          height: 0;
          overflow: hidden;
        }
        &.mobile {
          display: block;
          position: static;
          width: 100%;
          height: initial;
          overflow: visible;
        }
      }
    }
  }

  /*Slider*/

  .slider {
    &__title {
      width: 100%;
      text-align: center;
      font-size: 2rem;
    }
    &__image {
      width: 80%;
    }
    &__description {
      width: 80%;
      text-align: center;
      padding-bottom: 3rem;
    }
    .swiper {
      &-slide {
        justify-content: center;
      }
    }
  }

  /*Howtobuy*/

  .how {
    &__title {
      font-size: 3rem;
      width: 100%;
      text-align: center;
      margin-bottom: 3rem;
    }
    &__pay {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    &__pay {
      &-button {
        width: 100%;
        max-width: 25rem;
        font-size: 1.2rem;
      }
      &-wrapper {
        width: 100%;
        margin-bottom: 5rem;
        position: relative;
      }
      &-info {
        position: static;
      }
      &-get {
        right: 50%;
        transform: translateX(50%);
      }
      &-error {
        width: 100%;
        word-break: break-word;
      }
    }
    &__diagram {
      img {
        width: 100%;
      }
    }
    &__definition {
      flex-direction: column;
      align-items: center;
      span {
        width: 65%;
        text-align: center;
        margin-bottom: 1.5rem;
      }
      img {
        width: 70%;
      }
    }
    &__crypto {
      position: fixed;
      top: 2vh;
      left: 3vw;
      width: 94vw;
      height: 96vh;
      overflow-y: scroll;
      background-color: #4c3892;
      padding-top: 5rem;
      padding-bottom: 6rem;
      &-list {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        &::before {
          display: none;
        }
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 15%;
        padding-right: 15%;
        font-size: 1.2rem;
        svg {
          width: initial;
          margin-left: 0;
          margin-right: 1rem;
        }
        span {
          margin-left: .5rem;
        }
      }
      &-form {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 1rem;
        &.active {
          margin-bottom: 3rem;
        }
      }
      &-input {
        font-size: 1.2rem;
        max-width: 100%;
        padding: 1rem 15% 1rem 1.5rem;
        outline: 1px solid @light-color;
        border-radius: .5rem;
      }
      &-check {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15%;
      }
      &-close {
        display: block;
      }
    }
    &__steps {
      flex-direction: column;
      align-items: center;
      &::before,
      &::after {
        display: none;
      }
      &-item {
        text-align: center;
        &:not(:last-of-type) {
          width: 80%;
          margin-bottom: 5rem;
          position: relative;
          &::after {
            content: '';
            width: 2.5rem;
            height: 2.5rem;
            background: url(../img/deco-arrow.png) no-repeat center/100% auto;
            position: absolute;
            right: 50%;
            top: initial;
            bottom: -4rem;
            transform: rotate(0) translateX(50%);
          }
        }
        &:last-of-type {
          margin-top: 1rem;
        }
      }
    }
  }

  .popup {
    padding-left: 16px;
    padding-right: 16px;
  }

  .js-wallet {
    pointer-events: auto;
  }

}

@media (max-width: 750px) {
  .subtitle {
    font-size: 3rem;
  }
  .button {
    font-size: 1.5rem;
  }

  /*Header*/

  .header {
    .button {
      font-size: 1.3rem;
      &_transparent {
        display: none;
      }
    }
  }

  /*Banner*/

  .main.index-page {
    background: none;
  }

  .banner {
    &__about {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__text {
      margin-bottom: 1rem;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
    }
    &__buttons {
      a {
        margin-left: 0;
        margin-right: 0;
        &:nth-of-type(2) {
          margin-left: 0;
        }
        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
      }
    }
    .button {
      &_transparent {
        display: block;
      }
    }
  }

  /*Charity*/

  .charity {
    padding-top: 8rem;
    &__title {
      width: 100%;
      text-align: center;
    }
    &__paragraph {
      width: 100%!important;
      text-align: center!important;
      margin-left: auto;
      margin-right: auto;
      &:not(:last-of-type) {
        margin-bottom: 3rem;
      }
      &:nth-of-type(1) {
        &::before {
          display: block;
          position: static;
          margin-left: auto;
          margin-right: auto;
          height: 15rem;
          margin-bottom: 2rem;
        }
      }
      &:nth-of-type(2) {
        &::before {
          display: block;
          position: static;
          margin-left: auto;
          margin-right: 3rem;
          margin-bottom: 2rem;
          transform: none;
        }
        &::after {
          display: block;
          position: static;
          margin-right: auto;
          width: 10rem;
          height: 10rem;
          transform: none;
          background-image: url("../img/charity-bg2-mob.png");
        }
      }
      &:nth-of-type(3) {
        &::after {
          display: block;
          position: static;
          height: 17rem;
          margin-left: auto;
          margin-right: auto;
          margin-top: 2rem;
        }
      }
    }
    &__subtitle {
      padding-bottom: 16rem;
      width: 100%;
      font-size: 1.6rem;
      &::before {
        display: none;
      }
      &::after {
        left: 50%;
        transform: translateX(-50%);
        background-size: auto 13rem;
      }
    }
  }

  /*Howtobuy*/

  .how {
    &__subtitle {
      font-size: 2.5rem;
    }
    &__paragraph {
      width: 100%;
    }
    &__swap {
      flex-direction: column;
      align-items: center;
      &-info {
        width: 100%;
        margin-bottom: 3rem;
      }
      &-title {
        text-align: center;
        font-size: 2.2rem;
        margin-bottom: 2rem;
      }
      &-image {
        width: 70%;
      }
    }
  }

  .popup {
    padding-top: 48px;
    padding-bottom: 40px;

    &__title {
      font-size: 22px;
      line-height: 1.82;
      letter-spacing: 1.1px;
      text-shadow: 2px 2px 0 #000;
      margin-bottom: 40px;

      .popup__title_mark {
        font-size: 22px;
        line-height: 1.82;
        letter-spacing: 1.1px;
        text-shadow: 2px 2px 0 #000;
      }
    }

    &__btns-container {
      flex-direction: column;
    }

    &__btn {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.8px;
      width: 240px;
      height: 56px;
      border-radius: 20px;
      border: solid 1px #000;
      margin: 0;

      &:last-child {
        margin-top: 16px;
      }
    }
  }

  /*Footer*/

  .footer {
    &__wrapper {
      justify-content: center;
      flex-direction: column;
    }
    &__contact {
      margin-bottom: 1.5rem;
      a {
        &:not(:last-of-type) {
          width: 2.5rem;
          height: 2.5rem;
        }
        &:last-of-type {
          margin-right: 0;
          font-size: 1.3rem;
        }
      }
    }
    &__links {
      a {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

}

@media (max-width: 600px) {

  .button {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  /*Slider*/

  .slider {
    &__image {
      width: 100%;
    }
    &__buttons {
      flex-direction: column;
    }
    &__more {
      margin-right: 0;
      padding-left: 2.5rem;
      margin-bottom: 3rem;
      font-size: 1.3rem;
      &::before {
        left: 0;
      }
    }
  }

  .vote__auth_loading {
    width: 140px;
    height: 140px;

    svg {
      width: 80px;
      height: 80px;
    }

    img {
      bottom: 36px;
      width: 80px;
    }
  }
}

@media (max-width: 500px) {

  html {
    font-size: 11px;
  }
  .title {
    font-size: 2.4rem;
  }
  .subtitle {
    font-size: 2.8rem;
  }

  /*Banner*/

  .banner {
    background-image: url("../img/banner-mob.png");
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    padding-top: 8rem;
    padding-bottom: 12rem;
    &__text {
      width: 100%;
      font-size: 1.3rem;
      &:first-of-type {
        margin-bottom: 14rem;
      }
      a {
        text-shadow: 0 0 8px @main-color;
      }
    }
    &__buttons {
      margin-top: 1rem;
      &-note {
        font-size: 1.3rem;
        color: @text-color;
      }
    }
  }

  /*Animate*/

  .banner {
    &__animate {
      &-title {
        font-size: 1.1rem;

        em {
          font-size: 1rem;
        }
      }
    }
  }

  /*Slider*/

  .slider {
    padding-top: 2rem;
    overflow: hidden;
    &__wrapper {
      width: 100vw;
    }
    &__title {
      font-size: 2.1rem;
      padding-left: 3vw;
      padding-right: 3vw;
      br {
        display: none;
      }
    }
    &__image {
      &:not(.mobile) {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
    &__description {
      width: 90%;
      padding-bottom: 2rem;
    }
    &__buttons {
      margin-top: 6rem;
      .button {
        min-width: 20rem;
      }
    }
  }

  /*Howtobuy*/

  .how {
    overflow: hidden;
    &__title {
      font-size: 2.2rem;
    }
    &__subtitle {
      font-size: 2.2rem;
      padding-left: 6.5rem;
      background-size: 4rem auto;
    }
    &__definition {
      img {
        width: 100%;
      }
    }
    &__swap {
      margin-left: -6vw;
      &-title {
        font-size: 2rem;
      }
      &-image {
        width: 85%;
      }
    }
    &__banner {
      width: 102vw;
      padding-left: 3rem;
      padding-right: 3rem;
    }
    &__steps {
      &-item {
        &:not(:last-of-type) {
          width: 95%;
        }
      }
    }
  }

  /*Charity*/

  .charity {
    &__title {
      em {
        font-size: 1.5rem;
      }
    }
  }

  .work {
    &__subtitle {
      font-size: 1.8rem;
    }
  }

  /*Toondao*/

  .toondao {
    overflow: hidden;
    padding-top: 5.5rem;
    .how {
      &__back {
        top: -4.5rem;
        left: -1rem;
        width: 4rem;
        height: 4rem;
      }
      &__paragraph {
        li {
          text-align: left;
        }
      }
      &__break {
        width: 100%;
      }
      &__image {
        &:not(.mobile) {
          display: none;
        }
        &.mobile {
          display: block;
          width: 100vw;
          margin-left: -5vw;
        }
      }
      &__pay {
        &-title {
          width: 100%;
          text-align: center;
        }
      }
      &__diagram {
        img {
          width: 100%;
        }
        p {
          width: 100%;
          font-size: 1.8rem;
        }
      }
    }
  }

}

@media (max-width: 380px) {

  html {
    font-size: 10px;
  }
}

@media (max-width: 350px) {
  .popup {
    &__title {
      font-size: 20px;

      .popup__title_mark {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 340px) {

  html {
    font-size: 9px;
  }

  .dapp-deeplink-button {
    max-width: 180px;
  }

}

@media (max-height: 740px) {
  .popup-container_wallet .popup {
    padding: 16px 24px 24px;
  }

  .popup-container_wallet .popup__title {
    margin-bottom: 10px;
  }

  .popup-container_wallet .popup__btn img {
    height: 64px;
    margin-bottom: 16px;
  }
}

@media (max-height: 620px) {
  .popup-container_wallet .popup {
    padding: 16px 16px 20px;
  }

  .popup-container_wallet .popup__title {
    font-size: 18px;
    line-height: 1.67;;
  }

  .popup-container_wallet .popup__btn {
    padding: 16px;
  }

  .popup-container_wallet .popup__btn img {
    height: 48px;
    margin-bottom: 8px;
  }
}

@media (max-height: 520px) {
  .popup-container_wallet .popup__title {
    font-size: 16px;
  }

  .popup-container_wallet .popup__btn h4 {
    font-size: 18px;
  }

  .popup-container_wallet .popup__btn {
    padding: 12px;
  }

  .popup-container_wallet .popup__btn p {
    font-size: 14px;
  }
}

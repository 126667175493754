/*Normalize*/

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p,
ul {
  margin: 0;
  padding: 0;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

button,
input,
textarea,
select {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  border: none;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
}

li {
  list-style-type: none;
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}


/*Fonts*/

@font-face {
  font-family: "Wicked Mouse";
  src: url("../fonts/WickedMouse.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}


/*Var*/

@main-color: #5F4CA8;
@light-color: #b39ef2;
@text-color: #ffffff;
@accent1-color: #f0c538;
@accent2-color: #ff8db1;
@accent3-color: #00B8A2;
@shadow-color: #000000;

/*Dev*/

.hidden {
  display: none !important;
}

/*Common*/

html {
  font-size: 15px;
  scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

body {
  font-family: 'Poppins', 'Arial', sans-serif;
  font-weight: 500;
  position: relative;
  margin: auto;
  background-color: @main-color;
  color: @text-color;
  min-width: 320px;
  min-height: 100vh;
  font-size: 1.5rem;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noscroll {
  overflow: hidden;
}

.wrapper {
  width: 75rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

a:hover {
  transition: .2s;
}

.button {
  display: inline-block;
  font-size: 1rem;
  line-height: 100%;
  font-weight: 600;
  color: @shadow-color;
  text-align: center;
  background-color: @accent1-color;
  min-width: 15rem;
  padding: 1.6rem 1.3rem;
  cursor: pointer;
  border-radius: 1.5rem;
  border: 1px solid @shadow-color;
  box-shadow: 4px 4px 0px @shadow-color;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    background-color: #d2ac30;
  }
  &_violet {
    margin-left: 1.5rem;
    background-color: #826CCB;
    &:hover {
      background-color: #7360b3;
    }
  }
  &_transparent {
    border: 1px solid @accent1-color;
    box-shadow: 4px 4px 0px @accent1-color;
    background-color: #5f4ca8;
    color: @accent1-color;
    &:hover {
      background-color: #6752ba;
    }
  }
  span {
    text-transform: none;
  }
}

.title {
  font-family: 'Wicked Mouse', 'Arial', sans-serif;
  font-size: 3.5rem;
  color: @text-color;
  letter-spacing: .3rem;
  line-height: 160%;
  text-shadow: 4px 4px 0px @shadow-color;
  span {
    color: @accent1-color;
  }
}

.subtitle {
  font-size: 3.5rem;
  font-weight: 800;
  color: @text-color;
  line-height: 135%;
  min-height: 5rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}


/*Header*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 10;
  transition: .3s;
  padding: 1.2rem 0;
  &.scrolled {
    background-color: @main-color;
    border-bottom: 4px solid #564497;
    padding-top: .5rem;
    padding-bottom: .5rem;
    .header__logo {
      height: 3.5rem;
    }
    .button {
      min-width: initial;
      &_transparent {
        position: static;
        margin-left: 1rem;
      }
    }
  }
  &__wrapper {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__logo {
    display: block;
    width: 5.5rem;
    height: 3.5rem;
    z-index: 3;
    transition: .3s;
    background: url("../img/logo.svg") no-repeat center / contain;
  }
  .button {
    margin-right: 0;
    text-transform: uppercase;
    padding: .8rem .8rem;
    min-width: 11rem;
    border-radius: .6rem;
    &_violet {
      background-color: @light-color;
      &:hover {
        background-color: #826CCB;
      }
    }
    &_transparent {
      position: absolute;
      right: 0;
      bottom: -3rem;
      min-width: 11rem;
    }
  }
  &_vote {
    .button {
      background: @light-color url(../img/discord.svg) no-repeat left 1rem center/2rem;
      padding: 1rem 1rem 1rem 4rem;
    }
  }
}

/*Banner*/

.main.index-page {
  background: url(../img/banner.png) no-repeat right top/58%;
}

.banner {
  padding-top: 9rem;
  padding-bottom: 6rem;
  min-height: 80vh;
  position: relative;
  &__wrapper {
    position: relative;
  }
  &__about {
    width: 58%;
  }
  &__title {
    margin-bottom: 3rem;
  }
  &__text {
    width: 85%;
    margin-bottom: 1.5rem;
    span {
      color: @accent1-color;
      font-weight: bold;
    }
    a {
      color: #04d7bd;
      font-weight: bold;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__subtitle {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    letter-spacing: .15rem;
    span {
      font-size: 1rem;
      color: @text-color;
    }
  }
  &__more {
    margin-top: 2rem;
    width: 80%;
    b {
      font-weight: 700;
    }
    span {
      font-weight: 700;
      color: @accent1-color;
    }
  }
  &__buttons {
    padding-top: 1rem;
    &-note {
      display: block;
      width: 100%;
      color: @light-color;
      font-size: 1rem;
      line-height: 100%;
      margin-bottom: 1.5rem;
    }
    a {
      &:nth-of-type(2) {
        margin-left: 1.5rem;
        background-color: @light-color;
        &:hover {
          background-color: #7360b3;
        }
      }
    }
  }
  .button {
    &_transparent {
      display: none;
    }
  }
  &__arrow {
    display: block;
    width: 3rem;
    height: 4.5rem;
    background: url("../img/animate-arrow.svg") no-repeat center / contain;
    position: absolute;
    left: 50%;
    bottom: -4rem;
    transform: translateX(-50%);
    animation: animate-arrow 2.5s infinite ease-in-out;
  }
}
@keyframes animate-arrow {
  0%,
  40%,
  100% {
    bottom: -4rem;
    transform: translateX(-50%);
  }
  20% {
    bottom: -7rem;
    transform: translateX(-50%) rotate(-15deg);
  }
}

/*Terms*/

.terms-page,
.privacy-page {
  padding-top: 10rem;
  padding-bottom: 8rem;

  section {
    margin-bottom: 2rem;
  }

  p {
    color: @text-color;
    margin-bottom: 2rem;
  }

  .title {
    font-size: 2rem;
    font-family: 'Poppins','Arial',sans-serif;
    text-shadow: none;
    letter-spacing: normal;
    margin-bottom: 2rem;
  }

  li {
    color: @text-color;
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 1rem;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

/*Footer*/

.footer {
  padding-top: 2.7rem;
  padding-bottom: 2.7rem;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__contact {
    display: flex;
    align-items: center;
    a {
      margin-right: 3rem;
      &:hover {
        opacity: .7;
      }
      &:not(:last-of-type) {
        display: block;
        width: 2rem;
        height: 2rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &:nth-of-type(1) {
        background-image: url("../img/footer-eth.svg");
      }
      &:nth-of-type(2) {
        background-image: url("../img/footer-tg.svg");
      }
      &:last-of-type {
        font-size: 1rem;
        color: @text-color;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  &__links {
    a {
      font-size: 1rem;
      color: @light-color;
      text-transform: uppercase;
      margin-left: 3rem;
      &:hover {
        opacity: .7;
      }
    }
  }
}

/*Cookies*/

.cookies {
  background: @accent1-color;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__text {
    width: 80%;
    padding-right: 5%;
  }
  &__button {
    font-size: 1.2rem;
    display: inline-block;
    background: @main-color;
    color: @text-color;
    cursor: pointer;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
  }
}

/*Animate*/

.banner {
  &__animate {
    margin-top: 3rem;
    &-title {
      width: fit-content;
      font-size: 1.2rem;
      text-align: center;
      letter-spacing: .15rem;
      margin-bottom: .3rem;
      position: relative;
      padding-right: 3rem;
      margin-left: auto;
      margin-right: auto;
      em {
        font-style: normal;
        font-size: .8rem;
      }
    }
    &-video {
      width: 100%;
      &.mobile {
        display: none;
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
    &-dots {
      position: absolute;
      left: calc(100% - 2.5rem);
      bottom: .5rem;
      display: flex;
      align-items: flex-end;
    }
    &-dot {
      display: block;
      width: .45rem;
      height: .45rem;
      background-color: @text-color;
      border-radius: 50%;
      box-shadow: 4px 4px 0px @shadow-color;
      margin-left: .2rem;
      margin-right: .1rem;
      animation: animate-dot 1.1s infinite ease-in-out;
      &.dot1 {

      }
      &.dot2 {
        animation-delay: -1s;
      }
      &.dot3 {
        animation-delay: -0.9s;
      }
    }
  }
}
@keyframes animate-dot {
  0%,
  40%,
  100% {
    width: .45rem;
    height: .45rem;
  }
  20% {
    width: .25rem;
    height: .25rem;
  }
}

/*Howtobuy*/

.how {
  padding-top: 9rem;
  padding-bottom: 10rem;
  min-height: 80vh;
  &__back {
    display: block;
    width: 3rem;
    height: 3rem;
    background: url("../img/back-arrow.svg") no-repeat center / 1.5rem;
    position: absolute;
    left: -5rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &__title {
    font-size: 3.5rem;
    line-height: initial;
    color: @text-color;
    margin-bottom: 6rem;
    span {
      color: @accent1-color;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 25rem;
    font-size: 1.5rem;
    padding: 1.6rem 4.5rem 1.6rem 2.5rem;
    background-image: url("../img/arrow-down.svg");
    background-position: right 2.5rem center;
    background-size: 1rem;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    margin-top: 1rem;
    &.active {
      background-color: #826ccb;
      box-shadow: -4px -4px 0 @shadow-color;
    }
    &.selected {
      background: @accent1-color url("../img/refresh.svg") no-repeat right 1.5rem center / 1rem;
      box-shadow: 4px 4px 0 @shadow-color;
      padding: .8rem 3.5rem .8rem 1.2rem;
      text-align: left;
      path {
        fill: @main-color;
      }
      svg {
        margin-right: 1rem;
      }
      span {
        color: @main-color;
        margin-left: .5rem;
      }
    }
    em {
      font-style: initial;
    }
  }
  &__pay {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 5rem;
    margin-top: 4rem;
    &-title {
      width: 65%;
      line-height: 150%;
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      color: @light-color;
      text-transform: uppercase;
    }
    &-wrapper {
      width: ~'calc(100% - 20rem)';
      display: flex;
      align-items: stretch;
    }
    &-input {
      width: 100%;
      font-family: 'Poppins', 'Arial', sans-serif;
      font-size: 2rem;
      font-weight: 700;
      text-align: right;
      color: @text-color;
      background-color: #564497;
      padding: .9rem 1.5rem .9rem 1.5rem;
      border-radius: 1.5rem 0 0 1.5rem;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      &::placeholder {
        color: #826CCB;
      }
    }
    &-button {
      background-color: @accent1-color;
      cursor: pointer;
      transition: .3s;
      margin-left: 0;
      margin-right: 0;
      text-transform: uppercase;
      &:hover {
        background-color: #e3ba5f;
      }
    }
    &-info {
      position: relative;
      display: flex;
    }
    &-eth {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: @light-color;
      padding-left: 5.5rem;
      padding-right: 1.5rem;
      background: #564497 url("../img/eth-icon.svg") no-repeat left 1.5rem center / 3rem;
      border-radius: 0 1.5rem 1.5rem 0;
      border-left: 2px solid @main-color;
    }
    &-get {
      width: max-content;
      position: absolute;
      right: 100%;
      top: calc(100% + .5rem);
      font-weight: 300;
      b {
        color: @accent1-color;
      }
    }
    &-thanks {
      width: 100%;
      font-style: italic;
      color: @light-color;
      margin-top: 1rem;
      span {
        display: block;
        font-weight: 700;
        color: #FFA987;
        text-transform: uppercase;
      }
      a {
        color: @accent2-color;
        font-weight: 700;
        text-decoration: underline;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  &__paragraph {
    color: @text-color;
    width: 80%;
    margin-bottom: 3rem;
    a {
      color: @accent3-color;
      font-weight: 700;
      text-decoration: underline;
      &:hover {
        opacity: .7;
      }
    }
  }
  &__diagram {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4rem;
    padding-top: 2rem;
    img {
      width: 75%;
    }
  }
  &__definition {
    width: 75%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 12rem;
    span {
      width: 45%;
      font-size: 1.5rem;
      color: @light-color;
      line-height: 150%;
    }
    b {
      color: @accent1-color;
    }
    img {
      width: 56%;
      margin-bottom: .3rem;
    }
  }
  &__swap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12rem;
    &-info {
      width: 48%;
    }
    &-title {
      font-size: 2.5rem;
      font-family: 'Wicked Mouse', 'Arial', sans-serif;
      letter-spacing: .3rem;
      line-height: 160%;
      text-shadow: 4px 4px 0px @shadow-color;
      margin-bottom: 4rem;
      a {
        color: @accent3-color;
        position: relative;
        &:hover {
          opacity: .7;
        }
        //&::after {
        //  content: '';
        //  width: 100%;
        //  height: 3px;
        //  background-color: @accent3-color;
        //  position: absolute;
        //  left: 0;
        //  bottom: 0;
        //}
      }
      span {
        font-family: 'Poppins', 'Arial', sans-serif;
      }
    }
    &-image {
      display: block;
      width: 46%;
      img {
        width: 100%;
      }
    }
  }
  &__banner {
    font-size: 1.4rem;
    color: @text-color;
    width: 100%;
    margin-left: auto;
    background-color: #826CCB;
    border: 1px solid @shadow-color;
    box-shadow: 4px 4px 0px @shadow-color;
    padding: 2rem 1.5rem;
    transform: rotate(-2deg);
    a {
      color: @accent1-color;
      font-weight: 700;
      text-decoration: underline;
    }
    strong {
      color: @accent2-color;
      font-weight: 700;
    }
    i {
      font-weight: 300;
    }
  }
  &__price {
    display: flex;
    flex-direction: column;
    width: 70%;
    letter-spacing: .1rem;
    >span {
      &:nth-of-type(1) {
        font-size: 2.5rem;
        text-transform: uppercase;
        margin-bottom: 1rem;
      }
    }
  }
  .howtobuy_click_price {
    font-size: 1.5rem;
    font-weight: 300;
    font-style: italic;
    &.mobile {
      display: none;
    }
  }
  &__toons {
    display: flex;
    flex-direction: column;
    width: 30%;
    text-align: right;
    b {
      color: @accent3-color;
    }
    >span {
      &:nth-of-type(1) {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }
      &:nth-of-type(2) {
        font-size: 1.5rem;
      }
    }
  }
  &__subtitle {
    font-size: 3.5rem;
    line-height: initial;
    color: @text-color;
    display: flex;
    align-items: center;
    padding-left: 7.5rem;
    background: url("../img/coin.png") no-repeat left center / 5rem auto;
    min-height: 5rem;
    span {
      color: @accent1-color;
    }
  }
  &__crypto {
    display: none;
    position: relative;
    z-index: 2;
    &.active {
      display: block;
    }
    &-list {
      display: grid;
      grid-template-columns: repeat(11, 1fr);
      background-color: #4c3892;
      margin-top: 2.5rem;
      position: relative;
      &::before {
        content: '';
        border: 0 solid transparent;
        border-right-width: .8rem;
        border-left-width: .8rem;
        border-bottom: 1.2rem solid #4c3892;
        position: absolute;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
      }
    }
    &-item {
      color: @text-color;
      flex-grow: 1;
      font-size: .65rem;
      line-height: initial;
      text-align: center;
      padding: 1rem .3rem .3rem;
      cursor: pointer;
      &:not(:last-of-type) {
        border-right: 1px solid #5f4ca8;
      }
      &:hover {
        background-color: @accent1-color;
        color: @shadow-color;
        path {
          fill: @shadow-color;
        }
      }
      span {
        color: @light-color;
      }
      svg {
        display: block;
        //width: 3rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: .7rem;
      }
    }
    &-form {
      display: none;
      justify-content: flex-end;
      margin-top: .5rem;
      &.active {
        display: flex;
      }
      &.send {
        input {
          outline: 1px solid @accent1-color;
        }
        button {
          cursor: pointer;
        }
        path {
          fill: @accent3-color;
        }
      }
    }
    &-input {
      font-size: .7rem;
      color: @light-color;
      background-color: #4c3892;
      border-radius: .3rem;
      padding: .5rem .8rem;
      max-width: 18rem;
      width: 100%;
      &::-moz-placeholder {
        color: @light-color;
      }
      &::-webkit-input-placeholder {
        color: @light-color;
      }
    }
    &-check {
      width: 1.5rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
    &-close {
      display: none;
      width: 4rem;
      height: 4rem;
      background: url("../img/close.svg") no-repeat center / contain;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }
  &__steps {
    display: none;
    justify-content: space-between;
    margin-top: 5rem;
    position: relative;
    &::before,
    &::after {
      content: '';
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;
      position: absolute;
    }
    &::before {
      width: 7.8rem;
      height: 8.5rem;
      background-image: url("../img/steps-arrow1.svg");
      bottom: 100%;
      right: 70%;
    }
    &::after {
      width: 14.5rem;
      height: 11.2rem;
      background-image: url("../img/steps-arrow2.svg");
      bottom: -10%;
      left: 65%;
    }
    &.active {
      display: flex;
      flex-wrap: wrap;
    }
    &-name {
      color: @text-color;
      font-weight: bold;
      span {
        font-weight: normal;
        opacity: .5;
      }
    }
    &-adress {
      overflow-wrap: anywhere;
      color: @accent1-color;
    }
    &-item {
      font-size: 1.4rem;
      &:not(:last-of-type) {
        color: @shadow-color;
        width: 29%;
        background-color: @light-color;
        border: 1px solid @shadow-color;
        box-shadow: 4px 4px 0px @shadow-color;
        padding: 1.5rem;
      }
      &:nth-of-type(1), &:nth-of-type(2) {
        position: relative;
        &::after {
          content: '';
          width: 2.5rem;
          height: 2.5rem;
          background: url("../img/deco-arrow.png") no-repeat center/ 100% auto;
          position: absolute;
          right: -4rem;
          top: 50%;
          transform: rotate(-90deg) translateX(40%);
        }
      }
      &:last-of-type {
        font-size: 3rem;
        line-height: initial;
        font-family: 'Wicked Mouse', 'Arial', sans-serif;
        text-transform: uppercase;
        text-shadow: 4px 4px 40px #5f4ca8, 4px 4px 0 @shadow-color;
        color: @accent1-color;
        width: 100%;
        text-align: center;
        margin-top: 5rem;
      }
      i {
        font-weight: 300;
      }
      a {
        color: #018777;
        font-weight: bold;
        text-decoration: underline;
      }
      svg {
        display: none;
      }
    }
  }
}
#howBuyButton {
  background-color: @accent2-color;
}
.how__pay-error {
  color: red;
  text-align: center;
  margin-top: 24px;
}
.how__pay-loading {
  color: @text-color;
  text-align: center;
  margin-top: 24px;
}
.how__pay-status {
  color: @text-color;
  margin-top: 24px;
}

/*Slider*/

.slider {
  padding-top: 6rem;
  padding-bottom: 6rem;
  &__title {
    text-align: right;
    font-size: 2.5rem;
    margin-bottom: 4rem;
    width: 85%;
    margin-left: auto;
  }
  .swiper {
    width: 100%;
    &-slide {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .button {
      max-width: 65%;
    }
    &-pagination {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: initial;
      left: initial;
      right: -5rem;
      bottom: initial;
      top: 18rem;
      transform: none;
      span {
        display: block;
        margin-top: .6rem!important;
        margin-bottom: .6rem!important;
        width: .5rem!important;
        height: .5rem!important;
        background: @light-color;
        &.swiper-pagination-bullet-active {
          background: @text-color;
          width: .7rem!important;
          height: .7rem!important;
        }
      }
    }
  }
  &__image {
    width: 65%;
    &.mobile {
      display: none;
    }
  }
  &__description {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 2rem;
    padding-bottom: 5rem;
  }
  &__text {
    width: 100%;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.mobile {
      display: none;
    }
  }
  &__more {
    font-size: 1rem;
    line-height: 150%;
    text-transform: uppercase;
    font-weight: bold;
    color: @accent1-color;
    text-decoration: underline;
    display: block;
    width: 30%;
    position: relative;
    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      background: url("../img/slider-more.svg") no-repeat center / contain;
      position: absolute;
      right: calc(100% + 1rem);
      top: 0;
    }
  }
}

/*Charity*/

.charity {
  padding-top: 10rem;
  overflow: hidden;
  &__title {
    font-size: 3rem;
    margin-bottom: 2.8rem;
    span {
      display: block;
    }
    em {
      font-size: 2rem;
      font-style: initial;
    }
  }
  &__paragraph {
    color: @text-color;

    &:not(:last-of-type) {
      margin-bottom: 10rem;
    }
    &:last-of-type {
      margin-bottom: 5rem;
    }
    &:nth-of-type(1) {
      width: 70%;
      position: relative;
      &::before {
        content: '';
        width: 22rem;
        height: 23rem;
        position: absolute;
        bottom: 0;
        left: 110%;
        background: url("../img/charity-bg1.png") no-repeat center bottom / auto 100%;
      }
    }
    &:nth-of-type(2) {
      width: 67%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      &::after {
        content: '';
        width: 15rem;
        height: 17rem;
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        background: url("../img/charity-bg2.png") no-repeat center bottom / auto 100%;
      }
      &::before {
        content: '';
        width: 7.5rem;
        height: 7.5rem;
        position: absolute;
        top: 50%;
        left: 105%;
        transform: translateY(-50%);
        background: url("../img/charity-bg3.png") no-repeat center bottom / auto 100%;
      }
    }
    &:nth-of-type(3) {
      width: 52%;
      text-align: right;
      margin-left: auto;
      position: relative;
      &::after {
        content: '';
        width: 26rem;
        height: 22rem;
        position: absolute;
        top: 0;
        right: 120%;
        background: url("../img/charity-bg4.png") no-repeat center bottom / auto 100%;
      }
    }
    &:nth-of-type(4) {
      width: 68%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    span {
      font-weight: bold;
      color: @accent1-color;
    }
    strong {
      font-weight: bold;
      color: @accent2-color;
    }
  }
  &__subtitle {
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 2rem;
    color: @text-color;
    letter-spacing: .3rem;
    line-height: 160%;
    text-shadow: 4px 4px 0px @shadow-color;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 5rem;
    position: relative;
    &::before,
    &::after {
      content: '';
      width: 12.5rem;
      height: 18.7rem;
      position: absolute;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: auto 100%;
    }
    &::before {
      background-image: url("../img/charity-hands1.png");
      right: 105%;
    }
    &::after {
      background-image: url("../img/charity-hands2.png");
      left: 105%;
    }
    em {
      color: @accent1-color;
      font-style: initial;
    }
    span {
      font-size: 1.5rem;
    }
    b {
      font-family: 'Poppins', 'Arial', sans-serif;
      font-weight: bold;
    }
  }
}

.how__wrapper {
  display: flex;
  flex-wrap: wrap;

  h1 {
    font-size: 24px;
    font-weight: 800;
    color: @text-color;
    line-height: 1.67;
    margin-bottom: 40px;
  }
}

/*Vote*/

.vote__form_balance {
  h2 {
    text-align: center;
    margin-bottom: 8px;
  }

  img {
    width: 48px;
    margin-right: 8px;
    margin-top: 8px;
  }

  p {
    display: flex;
    align-items: flex-start;
    word-break: break-word;
    margin-bottom: 40px;
  }

  .balance {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 2.4px;
    text-align: right;
  }
}

.vote__form {
  display: flex;
  flex-direction: column;

  h2 {
    text-align: left;
    margin-bottom: 24px;
  }

  input[type="radio"] {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    height: 0;
    width: 0;

    &::after {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 72px;
      background-color: #564497;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%);
    }

    &:checked {
      &::before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 72px;
        background-color: @accent2-color;
        position: absolute;
        top: 0;
        left: 8px;
        transform: translateY(-50%);
        z-index: 5;
      }
    }
  }

  label {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: 1.2px;
    position: relative;
    padding-left: 56px;
    margin-bottom: 24px;
  }

  input:checked + span {
    color: @accent2-color;
  }

  input[type="text"] {
    width: 100%;
    height: 72px;
    padding: 0 24px;
    border-radius: 24px;
    background-color: #564497;
    font-size: 24px;
    font-weight: 600;
    color: @light-color;

    &::placeholder {
      font-size: 24px;
      font-weight: 600;
      color: @light-color;
      text-transform: uppercase;
    }
  }

  button {
    width: 240px;
    height: 72px;
    margin-top: 8px;
  }
}

.vote__form_message_success {
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  color: @light-color;
  line-height: 1.5;
  text-align: center;
  margin-top: 16px;

  a {
    font-weight: bold;
    color: @accent2-color;
  }
}

.vote__form_message_error {
  color: red;
  text-align: center;
  margin-top: 24px;
}

.vote__form_loading {
  text-align: center;
  margin-top: 24px;
}

.vote-page {
  .how__wrapper {
    max-width: 800px;
    position: relative;
  }

  .how {
    display: flex;
  }
}

.vote__auth_wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.vote__auth_loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border: solid 4px @light-color;
  border-radius: 50%;
  box-sizing: border-box;

  img {
    width: 124px;
    position: absolute;
    bottom: 48px;
    z-index: 5;
  }

  &-1,
  &-2 {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    left: -4px;
    top: -4px;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      border: 4px solid @light-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-sizing: border-box;
      animation: spinner 8s linear infinite;
    }
  }

  &-1:after {
    animation-delay: 0;
  }

  &-1:before {
    animation-delay: 2s;
  }

  &-2:after {
    animation-delay: 4s;
  }

  &-2:before {
    // content: none;
    animation-delay: 6s;
  }

  &-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@keyframes spinner {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    width: 300%;
    height: 300%;
    opacity: 0;
  }
}

.vote__auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    font-size: 56px;
    font-weight: 800;
    line-height: 1.43;
    text-transform: uppercase;
  }

  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.67;
    margin-top: 24px;
    span {
      font-weight: bold;
    }
  }

  .button {
    width: 240px;
    height: 72px;
    margin-top: 64px;
  }
}

.vote__download_metamask_wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 56px;
    font-weight: 800;
    color: @text-color;
    line-height: 1.43;
    text-transform: uppercase;

    span {
      color: #f6851b;
    }
  }

  p {
    font-size: 24px;
    font-weight: 500;
    color: @text-color;
    line-height: 1.67;
    margin-top: 24px;

    span {
      font-weight: bold;
    }
  }

  .button {
    width: 240px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @accent1-color;
    margin-top: 64px;
  }
}

.popup-show {
  overflow: hidden;
}

.popup-container {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;

  &_wallet {
    .popup {
      border-radius: 16px;
      background-color: #826ccb;
      box-shadow: none;
      border: none;
      padding: 24px 35px 40px;

      &__title {
        font-family: 'Poppins', 'Arial', sans-serif;
        font-size: 24px;
        margin-bottom: 24px;
        line-height: 40px;
        text-shadow: none;
      }

      &__close {
        right: 6px;
        top: -33px;
        width: 30px;
        height: 30px;

        svg {
          fill: #fff;
        }
      }

      &__btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #b39ef2;
        border: solid 1px #000;
        box-shadow: 4px 4px 0 0 #000;
        width: 100%;
        height: auto;
        padding: 24px 24px 16px;

        &:hover {
          background-color: #ffd06a;
          box-shadow: inset 4px 4px 0 0 #000;
        }

        img {
          height: 88px;
          margin-bottom: 24px;
        }

        h4 {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.67;
          margin: 0;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          text-align: center;
          color: #564497;
        }
      }
    }
  }
}

.popup {
  max-width: 880px;
  width: 90%;
  border-radius: 2px;
  box-shadow: 4px 4px 0 0 @shadow-color;
  border: solid 1px @shadow-color;
  background-color: #826ccb;
  box-sizing: border-box;
  position: relative;
  padding: 56px 130px 64px;

  &__title {
    font-family: 'Wicked Mouse', 'Arial', sans-serif;
    font-size: 48px;
    color: @text-color;
    line-height: 1.67;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    text-shadow: 4px 4px 40px #5f4ca8, 4px 4px 0 @shadow-color;
    text-align: center;
    margin-bottom: 48px;

    span {
      color: @accent1-color;
    }

    .popup__title_mark {
      font-family: 'Poppins', 'Arial', sans-serif;
      font-weight: 800;
      color: @text-color;
      font-size: 48px;
      line-height: 1.67;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      text-shadow: 4px 4px 40px #5f4ca8, 4px 4px 0 @shadow-color;
    }
  }

  &__btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    font-family: 'Poppins', 'Arial', sans-serif;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: @shadow-color;
    width: 240px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    border-radius: 24px;
    box-shadow: 4px 4px 0 0 @shadow-color;
    border: solid 1px @shadow-color;
    background-color: #fec202;
    cursor: pointer;
    transition: .2s;
    margin: 0 24px;

    &:hover {
      position: relative;
      top: 2px;
      left: 2px;
      box-shadow: 2px 2px 0 0 @shadow-color;
      background-color: @light-color;
      transition: .2s;
    }
  }

  &__close {
    position: absolute;
    right: 6px;
    top: 6px;
    background: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 4px;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

// --

.dapp-deeplink-container {
  height: 72px;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  padding-right: 44px;

  &.shown {
    display: flex;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(95, 76, 168, 0) 0%, #5f4ca8 100%);
  }

  .dapp-deeplink-button {
    font-size: 12px;
  }

  img {
    width: 40px;
    margin-top: 6px;
    margin-right: 4px;
  }
}

.dapp-deeplink-button {
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 240px;
  background-color: @accent1-color;
  margin: 0;
}

.js-place-time {
  font-weight: 300;
  font-style: italic;
}

.howtobuy_click_price {

  >span {
    color: @accent3-color;
    font-weight: bold;
  }
  strong {
    font-size: 1.5rem;
    line-height: initial;
    display: block;
    font-style: italic;
    font-weight: 300;
  }
}

.network_prod,
.network_debug,
.login,
.vote__zero_balance_wrapper p,
.vote__auth p,
.vote__form_balance h2,
.vote__form_balance p,
.vote__form h2,
.vote__form label,
.vote__form_loading {
  color: @text-color;
}

.mb-0 {
  margin-bottom: 0;
}

.dropdown-title {
  margin-top: 4rem;
}

.walletconnect-modal__footer {
  justify-content: flex-start !important;
  overflow: auto !important;
  padding-bottom: 12px !important;
}

.walletconnect-connect__button__text {
  line-height: 1.25 !important;
}

.walletconnect-qrcode__base {
  overflow: auto !important;
  display: flex !important;
  align-items: center !important;
  padding-top: 60px !important;
}

.walletconnect-modal__base {
  width: 100% !important;
  transform: none !important;
  top: 0 !important;
  padding-bottom: 10px !important;
}

@import "media-queries";
